<template>
  <ShadowCard
    :param="{
      name: 'Securities Master',
      showButtons: true,
    }"
    :isLoading="isLoading">
    <EditableTable
      :fields="fields"
      :storeData="storeData"
      :storePath="storePath"
      :id_property="id_property"
      :item_name="'Securities'"
      :initialSort="[{ field: 'asset_id', type: 'asc' }]"
      @on-cell-click="onCellClick" />
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import EditableTable from "../account/EditableTable.vue";

export default {
  name: "securities",
  components: { EditableTable, ShadowCard },
  data() {
    return {
      isLoading: true,
      storeData: "SECURITIES",
      storePath: "securities",
      id_property: "asset_id",
      fields: [
        {
          type: "number",
          field: "asset_id",
          label: "ID",
          thClass: "text-center",
          tdClass: "text-left",
          needToBeInteger: true,
          editable: false,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "name",
          label: "Name",
          tdClass: "text-left underline",
          thClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "description",
          label: "Description",
          thClass: "text-center",
          tdClass: "text-left",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "bbg_ticker",
          label: "Bbg Ticker",
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "ticker",
          label: "Ticker",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "multiplier",
          label: "Multiplier",
          thClass: "text-center",
          tdClass: "text-center",
          precision: 6,
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          field: "asset_class",
          label: "Asset Class",
          thClass: "text-center",
          tdClass: "text-center",
          options: [
            { label: "Comdty", value: "Comdty" },
            { label: "Corp", value: "Corp" },
            { label: "Curncy", value: "Curncy" },
            { label: "Equity", value: "Equity" },
            { label: "ETF", value: "ETF" },
            { label: "ETN", value: "ETN" },
            { label: "Future", value: "Future" },
            { label: "Fwd", value: "Fwd" },
            { label: "Govt", value: "Govt" },
            { label: "HedgeFnd", value: "HedgeFnd" },
            { label: "Index", value: "Index" },
            { label: "Loan", value: "Loan" },
            { label: "MonMkt", value: "MonMkt" },
            { label: "Mtge", value: "Mtge" },
            { label: "Muni", value: "Muni" },
            { label: "N/A", value: "N/A" },
            { label: "Option", value: "Option" },
            { label: "Pfd", value: "Pfd" },
            { label: "PreMet", value: "PreMet" },
            { label: "PrvEquity", value: "PrvEquity" },
            { label: "RealEst", value: "RealEst" },
            { label: "SP-Comdty", value: "SP-Comdty" },
            { label: "SP-Curncy", value: "SP-Curncy" },
            { label: "SP-Equity", value: "SP-Equity" },
            { label: "SP-FixInc", value: "SP-FixInc" },
            { label: "SP-Index", value: "SP-Index" },
            { label: "SP-RealEst", value: "SP-RealEst" },
            { label: "Swap", value: "Swap" },
            { label: "Warrant", value: "Warrant" },
          ],
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "isin",
          label: "ISIN",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "cusip",
          label: "CUSIP",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "CUSIP",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "sedol1",
          label: "SEDOL1",
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "SEDOL1",
            trigger: "keyup",
          },
        },

        {
          type: "select",
          field: "currency_id",
          label: "Ccy",
          tdClass: "text-center",
          thClass: "text-center",
          options: this.currencies,
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Ccy",
            trigger: "keyup",
          },
        },
        {
          field: "Actions",
          label: "Actions",
          actions: "actions",
          thClass: "text-center",
          tdClass: "text-center",
          html: true,
        },
      ],
    };
  },
  watch: {
    currenciesList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("currency_id", newVal);
      },
    },
  },
  mounted() {
    this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");

    this.setEditableFields();
  },
  methods: {
    updateSelectOptions(fieldName, options) {
      const fieldToUpdate = this.fields.find(
        (field) => field.field === fieldName
      );
      if (fieldToUpdate) {
        fieldToUpdate.options = options;
      }
    },
    setFieldId() {
      this.$store.commit("SET_TABLE_FIELD_ID", this.id_property);
    },
    setEditableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      this.$store.commit("SET_TABLE_EDITABLEFIELDS", editableFields);
    },
    onCellClick(param) {
      if (param.column.field === "name") {
        let assetIdentifier =
          param.row.figi ||
          param.row.isin ||
          param.row.sedol1 ||
          param.row.cusip ||
          param.row.telekurs ||
          param.row.ticker ||
          param.row.bbg_ticker;

        this.$store.commit("SET_ASSET_IDENTIFIER", assetIdentifier);
        this.$store.dispatch("loadLastSecurityDataPrice");
        this.$store.dispatch("loadHistoricalSecurityDataPrice");
        this.$store.dispatch("loadSecurityReturnToDate");

        this.$router.push({ name: "SecurityOverview" });
      }
    },
  },
  computed: {
    editableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      return editableFields;
    },
    currenciesList() {
      return this.$store.state.currenciesList.map((currency) => ({
        value: currency.currency_code,
        label: currency.currency_code + " - " + currency.description,
      }));
    },
  },
};
</script>

<style lang="css">
td.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.secondaryButton {
  color: #3c4b64;
  border: #3c4b64;
  background-color: white;
  min-width: 180px;
  background: rgb(255, 255, 255);
  padding-left: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
}
</style>
